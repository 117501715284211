import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const UpperFold = ({
  title,
  subtitle,
  children,
  isCentered,
  hasBackground,
}) => (
  <section>
    <div className={classNames({ 'is-light': hasBackground }, 'hero mt-1')}>
      <div className="hero-body">
        {title ? (
          <h1
            className={classNames('title has-text-primary is-3 py-2', {
              'has-text-centered': isCentered,
            })}
            id="header-text"
          >
            {title}
          </h1>
        ) : (
          ''
        )}

        {subtitle ? <h1 className="subtitle is-5">{subtitle}</h1> : ''}
        {children}
      </div>
    </div>
  </section>
)

UpperFold.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  isCentered: PropTypes.bool,
  hasBackground: PropTypes.bool,
}

export default UpperFold
