import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import UpperFold from "../page-content/upper-fold"

const Breadcrumbs = ({ isCentered }) => {
  return (
    <nav
      className={classNames({ "is-centered": isCentered }, "breadcrumb")}
      aria-label="breadcrumbs"
    >
      <ul>
        <li>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
        </li>
        <li>
          <Link to="/faq">FAQs</Link>
        </li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
      </ul>
    </nav>
  )
}

const Footer = () => (
  <UpperFold hasBackground>
    <Breadcrumbs isCentered />
    <div className="has-text-centered pb-1">
      <small>
        © {new Date().getFullYear()} · MedGrocer (WellBridge Health, Inc.)
      </small>
    </div>
  </UpperFold>
)

export default Footer
