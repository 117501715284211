import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ContentFold from "../page-content/content-fold"
import styles from "./landing-page.module.scss"
import { withTranslation } from "react-i18next"

const aboutInfo = [
  {
    image: "medgrocerLogo",
    text:
      "MedGrocer's digitally-enabled online pharmacy, corporate clinics, and patient programs empower our customers to get their medicines conveniently, cost-effectively, and intelligently.<br/><br/>We serve over 500,000 patients either directly or through partnerships with employers and pharmaceutical companies.",
  },
  {
    image: "novartisLogo",
    text:
      "Novartis is structured to deliver innovative products, explore global scale, and respond to new opportunities and risks. Our divisions - Innovative Medicines and Sandoz - are supported by functional organizations with global scale.<br/><br/>Research and development (R&D) is at the core of our company and central to the Novartis strategy.",
  },
]

const AboutColumn = ({ image, description }) => (
  <div className="column">
    <div className={styles.imageContainer}>
      <Img fluid={image} />
    </div>
    <p
      className={styles.description}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </div>
)

const About = ({ t }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          entrestoLogo: file(relativePath: { eq: "entresto-logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          medgrocerLogo: file(relativePath: { eq: "mgsolo_teal.png" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          novartisLogo: file(relativePath: { eq: "novartis.png" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <ContentFold>
            <div className="columns pt-3 pb-4 is-6 is-variable">
              <div className={styles.anchor} id="entresto" />
              {aboutInfo.map((item, index) => {
                return (
                  <AboutColumn
                    key={index}
                    image={data[`${item.image}`].childImageSharp.fluid}
                    description={t(item.text)}
                  />
                )
              })}
            </div>
          </ContentFold>
        </>
      )}
    />
  )
}

export default withTranslation()(About)
