import React from 'react'

import HeartCareProgram from '../components/landing-page/heart-care-program'
import About from '../components/landing-page/about'
import Footer from '../components/landing-page/footer'
import Layout from '../components/layout'
import Video from '../components/landing-page/video'
import DateDemo from '../components/forms/DateDemo';
import SEO from '../components/seo'

const IndexPage = props => (
  <Layout location={props.location} disableBottomNavbar showBanner>
    <SEO
      title="New Date Config Demo"
      // keywords={[
      //   `novartis`,
      //   `medgrocer`,
      //   `pulse care`,
      //   `entresto`,
      //   `sacubitril-valsartan`,
      // ]}
    />
    {/* <HeartCareProgram />
    <Video />
    <About /> */}
    <DateDemo />
    {/* <Footer /> */}
  </Layout>
)

export default IndexPage
